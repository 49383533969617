import React from 'react'
import { Grid, Toast, Typography } from '~components'
import css from './AccountToast.module.scss'

interface AccountToastProps {
  title: string
  subscribed?: boolean
  subtitle?: string
  action?: {
    title: string
    onPress?: (() => void) | (() => Promise<void>)
  }
}

const AccountToast = ({
  title,
  subtitle,
  subscribed,
  action,
}: AccountToastProps) => (
  <button
    onClick={action.onPress}
    disabled={!action.onPress}
    className={`w-full `}
  >
    <Toast subscribed={subscribed}>
      <div className="flex flex-row items-center justify-between mb-16">
        <h4 className="h4">Current plan</h4>
        <div className="caption text-blue">{action.title}</div>
      </div>
      <div>
        <div>
          <h3 className="h2 text-blue">{title}</h3>
          {subtitle ? (
            <div className={'caption mt-1 mb-1'}>{subtitle}</div>
          ) : null}
        </div>
      </div>
    </Toast>
  </button>
)

export default AccountToast
