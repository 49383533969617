import { getReadablePricing, getReadablePricingSignUp } from '~utils/pricing'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { PricingTableItemProps } from '~components'

export const StripePlanInterval = types.enumeration(['year', 'month'])

export const StripeBillingAddress = types
  .model('StripeBillingAddress', {
    city: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    line1: types.maybeNull(types.string),
    line2: types.maybeNull(types.string),
    postal_code: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    automatic_tax: types.maybeNull(
      types.enumeration([
        'supported',
        'unrecognized_location',
        'failed',
        'not_collecting',
      ]),
    ),
  })
  .views((self) => ({
    get hasValidAutomaticTax() {
      return (
        self.automatic_tax === 'not_collecting' ||
        self.automatic_tax === 'supported'
      )
    },
    get readable() {
      return {
        line1: `${self.line1} ${self.line2}`,
        line2: `${self.city}, ${self.state}, ${self.country} ${self.postal_code}`,
      }
    },
  }))

export const StripeSubscription = types
  .model('StripeSubscription', {
    id: types.maybe(types.identifier),
    automatic_tax: types.maybe(
      types.model({
        enabled: types.boolean,
      }),
    ),
    cancel_at: types.maybeNull(types.number),
    cancel_at_period_end: types.maybeNull(types.boolean),
    canceled_at: types.maybeNull(types.number),
    billing_cycle_anchor: types.maybe(types.number),
    collection_method: types.maybe(types.string),
    created: types.maybe(types.number),
    current_period_end: types.maybe(types.number),
    current_period_start: types.maybe(types.number),
    customer: types.maybe(types.string),
    discount: types.maybeNull(
      types.model({
        coupon: types.model({
          id: types.string,
          name: types.string,
          duration: types.string,
        }),
        customer: types.string,
        id: types.string,
        object: types.string,
        start: types.number,
        subscription: types.string,
      }),
    ),
    latest_invoice: types.maybe(types.string),
    livemode: types.maybe(types.boolean),
    object: types.maybe(types.string),
    plan: types.maybe(
      types.model({
        id: types.maybe(types.string),
        active: types.maybe(types.boolean),
        amount: types.maybe(types.number),
        amount_decimal: types.maybe(types.string),
        billing_scheme: types.maybe(types.string),
        created: types.maybe(types.number),
        currency: types.maybe(types.string),
        interval: StripePlanInterval,
      }),
    ),
    quantity: types.maybe(types.number),
    start_date: types.maybe(types.number),
    status: types.maybe(types.string),
    trial_end: types.maybe(types.number),
    trial_start: types.maybe(types.number),
  })
  .views((self) => ({
    get readableInterval() {
      return self.plan?.interval === 'year' ? 'Yearly' : 'Monthly'
    },
    get readablePricing(): PricingTableItemProps {
      return getReadablePricing({
        interval: self.plan.interval,
        amount: self.plan.amount,
        current_period_end: self.current_period_end,
      })
    },
  }))
export type IStripeSubscription = Instance<typeof StripeSubscription>

export const StripeCoupon = types.model('StripeCoupon', {
  coupon_name: types.string,
  discount_price: types.number,
  id: types.string,
  name: types.string,
  regular_price: types.number,
  starts_at: types.maybeNull(types.string),
  stops_at: types.maybeNull(types.string),
})

export const StripeCouponStatus = types.enumeration([
  'initial',
  'success',
  'loading',
  'error',
])
export type IStripeCouponStatus = Instance<typeof StripeCouponStatus>

export type IStripeCoupon = Instance<typeof StripeCoupon>

export const StripePrice = types
  .model('StripeBillingAddress', {
    id: types.maybeNull(types.string),
    coupon: types.maybeNull(StripeCoupon),
    active: types.maybeNull(types.boolean),
    billing_scheme: types.maybeNull(types.string),
    created: types.maybeNull(types.number),
    currency: types.maybeNull(types.string),
    live_mode: types.maybeNull(types.boolean),
    lookup_key: types.maybeNull(types.string),
    nickname: types.maybeNull(types.string),
    object: types.maybeNull(types.string),
    product: types.maybeNull(types.string),
    recurring: types.maybe(
      types.model({
        aggregate_usage: types.maybeNull(types.string),
        interval: StripePlanInterval,
        interval_count: types.maybeNull(types.number),
        trial_period_days: types.maybeNull(types.number),
        usage_type: types.maybeNull(types.string),
      }),
    ),
    tax_behavior: types.maybeNull(types.string),
    tiers_mode: types.maybeNull(types.string),
    transform_quantity: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    unit_amount: types.maybeNull(types.number),
    unit_amount_decimal: types.maybeNull(types.string),
  })
  .actions((self) => ({
    removeCoupon() {
      self.coupon = null
    },
  }))
  .views((self) => ({
    get readablePricing() {
      return getReadablePricingSignUp({
        id: self.id,
        amount: self.unit_amount,
        coupon: self.coupon,
        interval: self.recurring.interval,
      })
    },
  }))

export type IStripePrice = Instance<typeof StripePrice>

export type SnStripeBillingAddress = SnapshotIn<typeof StripeBillingAddress>

export const StripePaymentMethod = types.model('StripePaymentMethod', {
  card: types.model({
    brand: types.maybeNull(types.string),
    checks: types.model({
      address_line1_check: types.maybeNull(types.string),
      address_postal_code_check: types.maybeNull(types.string),
      cvc_check: types.maybeNull(types.string),
    }),
    country: types.maybeNull(types.string),
    exp_month: types.maybeNull(types.number),
    exp_year: types.maybeNull(types.number),
    fingerprint: types.maybeNull(types.string),
    funding: types.maybeNull(types.string),
    generated_from: types.maybeNull(types.string),
    last4: types.maybeNull(types.string),
    networks: types.model({
      available: types.maybeNull(types.array(types.string)),
    }),
    three_d_secure_usage: types.maybeNull(
      types.model({
        supported: types.maybeNull(types.boolean),
      }),
    ),
  }),
  created: types.maybeNull(types.number),
  customer: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  livemode: types.maybeNull(types.boolean),
  object: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
})

export const StripeCustomer = types
  .model('StripeCustomer', {
    default_payment_method: types.maybeNull(StripePaymentMethod),
    upcoming_invoice: types.maybeNull(
      types.model({
        subtotal: types.number,
        amount_due: types.number,
      }),
    ),
  })
  .views((self) => ({
    get readablePaymentMethod() {
      const card = self.default_payment_method?.card
      if (card) {
        const month =
          card.exp_month.toString().length < 2
            ? `0${card.exp_month}`
            : card.exp_month
        const year = card.exp_year.toString().substring(2, 4)

        return {
          brand: card.brand,
          lastFour: `•••• ${card.last4}`,
          date: `${month}/${year}`,
        }
      } else {
        return undefined
      }
    },
  }))

export type IStripeCustomer = Instance<typeof StripeCustomer>

// Storing only the required data, for more data, check the log in the stripeStore and see the available fields.
export const StripeInvoice = types.model({
  account_country: types.string,
  total: types.number,
  status: types.string,
  created: types.number,
  invoice_pdf: types.string,
})

export type IStripeInvoice = Instance<typeof StripeInvoice>
