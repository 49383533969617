import React, { useMemo } from 'react'
import { Typography } from '~components'
import { observer } from 'mobx-react'
import { useMst } from '~stores'
import { DEFAULT_TIMEZONE, formatDateString } from '~utils'

const PricingTableTerms: React.FC = () => {
  const { stripeStore } = useMst()

  const date = useMemo(
    () =>
      stripeStore.anyCoupon
        ? formatDateString(stripeStore.anyCoupon.stops_at)
        : undefined,
    [stripeStore.anyCoupon],
  )

  return (
    <div className="pt-8 mb-4">
      <Typography.Text type={'label'} className={'mb-2'}>
        Recurring billing & trials
      </Typography.Text>
      <Typography.Text type={'disclaimer'} className={'mb-8'}>
        All subscriptions automatically renew unless canceled at least 24 hours
        before the current period ends. You need to manage your subscription on
        the platform where you signed up.
      </Typography.Text>
      <Typography.Text type={'label'} className={'mb-2'}>
        Cancel anytime
      </Typography.Text>
      <Typography.Text type={'disclaimer'} className={'mb-8'}>
        Free trials are for new customers and can only be redeemed once. After
        your free trial, the subscription will begin unless canceled 24 hours
        before the end of the trial.
      </Typography.Text>
      {stripeStore.anyCoupon ? (
        <>
          <Typography.Text type={'label'} className={'mb-2'}>
            Promotions
          </Typography.Text>
          <Typography.Text type={'disclaimer'} className={'mb-2'}>
            New Years Promotion ends on January 15th, 2024 at midnight EST.
            Promotional pricing available for annual and monthly billing cycles,
            with one-year duration for annual and one-month duration for monthly
            subscriptions. Subscription will auto-renew at the standard price of
            $119.99 USD for annual and $14.99 USD for monthly.
          </Typography.Text>
          <Typography.Text type={'label'} className={'mb-2'}>
            NO REFUNDS ON DISCOUNTED SUBSCRIPTIONS
          </Typography.Text>
          <Typography.Text type={'disclaimer'} className={'mb-2'}>
            All discounted subscription purchases are non-refundable. Refunds
            will not be provided after the initial 7-day free trial.
          </Typography.Text>
        </>
      ) : null}
    </div>
  )
}

export default observer(PricingTableTerms)
