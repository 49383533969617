import React from 'react'
import { useMst } from '~stores'
import { Checkbox, CheckboxEmpty } from '~svg'
import { Typography } from '~components'
import { useRouter } from 'next/router'
import classNames from 'classnames/bind'
import css from './PricingTable.module.scss'

const cx = classNames.bind(css)

export interface PricingTableItemProps {
  title: string
  subtitle: string
  disclaimer?: string
  current?: boolean
  checked?: boolean
  clickable?: boolean
  banner?: string
  bannerCrossed?: boolean
  promotion?: string
  addCurrent?: 'Monthly' | 'Yearly'
  isDisabled?: boolean
  card?: {
    type: string
    price: string
    priceNoSup: string
    trial: string
    interval: string
    disclaimer: string
    discount?: {
      label: string
      monthly: string
    }
    renewalDate: string
  }
}

const PricingTableItem = ({
  title,
  subtitle,
  disclaimer,
  current = false,
  checked = false,
  clickable = true,
  banner,
  bannerCrossed = false,
  promotion,
  addCurrent,
  isDisabled,
  card,
}: PricingTableItemProps) => {
  const router = useRouter()
  const { userStore } = useMst()

  const handleChangeClick = () => {
    router.push('/account/subscription')
  }

  return (
    <div
      className={cx({
        item_container: true,
        clickable: !isDisabled,
        isDisabled,
        current,
        checked,
      })}
      role="button"
      onClick={current ? handleChangeClick : null}
    >
      {promotion ? (
        <div className={css.item_notification}>
          <Typography.Title className={'text-black'} level={'h4'}>
            {promotion}
          </Typography.Title>
        </div>
      ) : null}
      <div
        className={cx(css.item_wrapper, {
          [css['discounted']]: banner,
        })}
      >
        {banner ? (
          <div className={cx({ item_discount: true, bannerCrossed })}>
            <Typography.Text className={'text-white mb-1'} type={'label-2'}>
              {card ? card.discount.label : ''}
            </Typography.Text>
            <Typography.Title
              className={css.promoFormat}
              level={'h5'}
              dangerouslySetInnerHTML={{
                __html: `${banner} • ${card ? card.discount.monthly : ''}`,
              }}
            ></Typography.Title>
          </div>
        ) : null}
        {checked ? (
          current ? (
            <div className="text-blue cursor-pointer absolute top-6 right-5 caption">
              Change
            </div>
          ) : (
            <div className={css.checkbox}>
              <Checkbox />
            </div>
          )
        ) : (
          card?.type != addCurrent &&
          !current && <div className={css.checkboxEmpty}></div>
        )}
        {card?.type === addCurrent && !current ? (
          <div className="absolute top-[19px] right-[18px] label bg-yellow rounded-full inline-flex items-center px-2">
            <div className="pt-1">Current Plan</div>
          </div>
        ) : null}
        {current ? (
          <>
            <div className="h4 mb-14">Current plan</div>
            <div className="h4 text-blue mb-1">{card ? card.type : title}</div>
            <div className="h2 text-blue mb-2">
              {card ? card.price : subtitle}
            </div>
            <div className="caption">{card ? card.disclaimer : disclaimer}</div>
          </>
        ) : (
          <>
            <Typography.Title
              level={'h4'}
              className={cx(css.title, 'pb-1')}
              dangerouslySetInnerHTML={{ __html: card ? card.type : title }}
            />
            <Typography.Title
              level={'h2'}
              className={cx(css.title, 'pb-4')}
              dangerouslySetInnerHTML={{ __html: card ? `$${card.price}` : '' }}
            />
            <div className="h-px w-full bg-black/10 mb-4"></div>
            {userStore.subscriptionStatus === 'active' ||
            userStore.subscriptionStatus === 'canceled' ? null : (
              <h5 className={'h5 mb-4'}>{card ? card.trial : ''}</h5>
            )}
            <Typography.Text
              type={'disclaimer'}
              dangerouslySetInnerHTML={{ __html: card ? card.disclaimer : '' }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default PricingTableItem
