import React, { useEffect, useState } from 'react'
import { formatDateNumber, formatDateString } from '~utils'
import InfoIcon from '~svg/InfoIcon.svg'
import { useMst } from '~stores'
import TaxModal from './TaxModal'

const Summary = ({ hide = false, summaryValues }) => {
  const rootStore = useMst()
  const { userStore, stripeStore } = rootStore

  const [taxOpen, setTaxOpen] = useState(false)

  if (hide) return

  if (summaryValues.type === '') {
    return
  } else {
    return (
      <>
        {taxOpen && (
          <TaxModal onClose={() => setTaxOpen(false)} open={taxOpen} />
        )}
        <h2 className={'text-blue mb-4 pt-8 h2'}>Summary</h2>
        {userStore.subscriptionStatus === 'active' &&
        summaryValues.type === 'Monthly' ? (
          <div>
            <h5 className="h5 mb-4">
              Your new plan will officially start on {summaryValues.date}
            </h5>
            <div className="caption mb-4">
              Switching from a Yearly to a Monthly subscription will take effect
              at the end of your current yearly billing period. The monthly
              subscription fee will be charged when your new billing cycle
              begins.
            </div>
            <div className="caption mb-8">
              You will keep all access and all data through the plan change.
            </div>
          </div>
        ) : null}
        {userStore.subscriptionStatus === 'active' &&
        summaryValues.type === 'Yearly' ? (
          <div>
            <h5 className="h5 mb-4">
              Your new plan will start immediately, {summaryValues.date}.
            </h5>
            <div className="caption mb-4">
              Upgrading from a Monthly to a Yearly Subscription will take effect
              immediately. Your new billing cycle starts today, and you will be
              charged the annual subscription fee at this time, with a credit
              for the monthly payment you've already made.
            </div>
            <div className="caption mb-8">
              You will keep all access and all data through the plan change.
            </div>
          </div>
        ) : null}
        <div className="bg-light-grey py-5 px-4 mb-10 rounded mt-4">
          <div className={'label mb-5'}>Starting {summaryValues.date}</div>
          <div className="mb-4">
            <div className="flex">
              <div>
                <div className={'copy-2 mb-1'}>
                  Alive {summaryValues.type} Plan
                </div>
              </div>
              <div className="flex-grow">
                <div className={'copy-2 mb-1 text-right'}>
                  ${summaryValues.price.value.toFixed(2)} USD
                </div>
              </div>
            </div>
          </div>
          {summaryValues.tax > 0 && (
            <div className={'copy-2 mb-5'}>
              <div className="flex">
                <div
                  className="flex items-center gap-1 cursor-pointer"
                  onClick={() => setTaxOpen(true)}
                >
                  <div className="leading-[14px]">Sales Tax</div> <InfoIcon />
                </div>
                <div className="flex-grow">
                  <div className={'mb-1 text-right'}>
                    ${summaryValues.tax.value.toFixed(2)} USD
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={'copy-2 pt-5 border-t border-black/30'}>
            <div className="flex">
              <div>Total</div>
              <div className="flex-grow">
                <div className={'mb-1 text-right'}>
                  ${summaryValues.total.value.toFixed(2)} USD
                </div>
              </div>
            </div>
          </div>
          {/* <div type={'caption'} className={'mt-5'}>
            Promotion ends on January 16, 2025 at midnight EST. Promotional
            pricing is for the first year. Subscription will auto-renew at the
            standard price of $119.99 USD for annual and $14.99 USD for monthly.
          </div> */}
        </div>
      </>
    )
  }
}

export default Summary
