import { IStripeCoupon } from '~stores'
import { formatDateNumber } from '~utils/date'
import { formatPrice } from '~utils/stripeUtils'
import { PricingTableItemRadioProps } from '~components/Pricing/PricingTableItemRadio'

interface GetReadablePricingParams {
  interval: 'year' | 'month'
  amount: number
  current_period_end: number
}

function formatDateToMonthDayYear(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }
  return date.toLocaleDateString('en-US', options)
}

export const getReadablePricing = ({
  interval,
  amount,
  current_period_end,
}: GetReadablePricingParams) => {
  const isAnnual = interval === 'year'
  const readableInterval = isAnnual ? 'Yearly' : 'Monthly'
  const readablePrice = `${formatPrice(amount)} USD`
  const renewal_text = `Renews ${formatDateNumber(current_period_end)}`

  return {
    value: interval,
    title: `${readableInterval} - ${readablePrice}`,
    subtitle: `${renewal_text}`,
    banner: isAnnual ? 'Best Value' : undefined,
  }
}

interface GetReadablePricingParamsSignUp {
  amount: number
  coupon?: IStripeCoupon
  id: string
  interval: 'year' | 'month'
}

/*
 * Returns the copies for the PricingTableItem.
 *
 * If we have a coupon we use it to get the amount, the discount banner
 * and the promotion pink badge.
 */
export const getReadablePricingSignUp = ({
  id,
  amount,
  coupon,
  interval,
}: GetReadablePricingParamsSignUp): PricingTableItemRadioProps => {
  const isAnnual = interval === 'year'
  const finalAmount = coupon ? coupon.discount_price : amount

  const readablePrice = `${formatPrice(
    finalAmount,
  ).dollars()} <small>.${formatPrice(finalAmount).cents()}</small>`
  const readablePriceNoSup = `${formatPrice(
    finalAmount,
  ).dollars()}.${formatPrice(finalAmount).cents()}`
  const readableInterval = isAnnual ? 'Yearly' : 'Monthly'

  const annualPrice = isAnnual
    ? coupon
      ? `$${formatPrice(finalAmount, 12)} USD/month for first year.`
      : // I need to extract a cent from the price because the library rounds to 10 the price.
        `$${formatPrice(finalAmount, 12)
          .subtract(1)
          .dollars()}<small>.${formatPrice(finalAmount, 12)
          .subtract(1)
          .cents()}/mo</small>`
    : coupon
    ? `$${formatPrice(finalAmount)} for first month.`
    : `$${formatPrice(finalAmount)}.`

  const couponBanner = coupon
    ? `${formatPrice(coupon.regular_price)} USD`
    : undefined
  const defaultBanner = isAnnual ? `Save 33%` : undefined
  const finalBanner = couponBanner ? couponBanner : defaultBanner

  const trialEndDate = new Date()
  trialEndDate.setDate(trialEndDate.getDate() + 7)
  let renewalDate = new Date(trialEndDate)

  const formattedRenewalDate = formatDateToMonthDayYear(renewalDate)

  return {
    value: id,
    title: `${readableInterval} - $${readablePrice}`,
    subtitle: `${annualPrice ? annualPrice : ''}`,
    banner: finalBanner,
    bannerCrossed: Boolean(coupon),
    promotion: coupon?.coupon_name,
    card: {
      type: readableInterval,
      price: readablePrice,
      priceNoSup: readablePriceNoSup,
      trial: 'Free for 7 days',
      interval: interval,
      disclaimer: `Auto-renews at $${readablePriceNoSup} USD per ${interval} until cancelled.`,
      discount: {
        label: 'Best Value',
        monthly: annualPrice,
      },
      renewalDate: formattedRenewalDate,
    },
  }
}
