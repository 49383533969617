import React from 'react'
import css from './Toast.module.scss'

interface ToastProps {
  subscribed?: boolean
}

const Toast: React.FC<ToastProps> = ({ children, subscribed }) => {
  return (
    <div
      className={`relative text-left bg-light-blue rounded-[20px] px-5 py-4 md:py-5 w-full ${
        subscribed ? css.current : ''
      }`}
    >
      {children}
    </div>
  )
}

export default Toast
